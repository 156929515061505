<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import NavBar from "~/components/VNavBar.vue";
import { useAuthStore } from "~/store/auth";
import { Routes } from "~/utils/routes";

const authStore = useAuthStore();
const route = useRoute();
const rootRef = ref<HTMLDivElement>();

const isNavBarShow = computed(
  () =>
    authStore.state.authorized &&
    route.name !== "404" &&
    route.path !== Routes.Landing &&
    route.path !== Routes.Chat &&
    route.name !== Routes.ProfileIncomplete &&
    route.path !== Routes.CookiePolicy &&
    route.path !== Routes.ProfileStickers &&
    route.path !== Routes.ProfileFavoriteCategories &&
    route.path !== Routes.Coupons &&
    route.path !== Routes.ProfileTransactions &&
    !route.path.includes(Routes.PaymentMethods) &&
    route.name !== "coupons-couponId",
);
</script>

<template>
  <div ref="rootRef" class="main">
    <slot />
  </div>
  <NavBar v-show="isNavBarShow" />
</template>

<style lang="postcss">
.main {
  display: flex;
  flex: 1;
  justify-content: center;
}
</style>
