<script setup lang="ts">
import { computed, onMounted } from "vue";
import type { FunctionalComponent, SVGAttributes } from "vue";
import { useRoute, useRouter } from "vue-router";
import ShopIcon from "~/assets/svg/icons/shop.svg";
import ShopColorIcon from "~/assets/svg/icons/shop_duotone.svg";
import CardIcon from "~/assets/svg/icons/card.svg";
import CardColorIcon from "~/assets/svg/icons/card_duotone.svg";
import HumanIcon from "~/assets/svg/icons/human.svg";
import HumanColorIcon from "~/assets/svg/icons/human_duotone.svg";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import useAnalytics from "~/composables/useAnalytics";
import { Routes } from "~/utils/routes";

enum NavName {
  Main,
  QrCode,
  Profile,
}
type INavItem = {
  name: NavName;
  icon: FunctionalComponent<SVGAttributes>;
  iconActive: FunctionalComponent<SVGAttributes>;
  text: string;
  link: string;
  counter: string;
};

const navItems: INavItem[] = [
  {
    name: NavName.Main,
    icon: ShopIcon,
    iconActive: ShopColorIcon,
    text: "Сегодня",
    link: Routes.Main,
    counter: "Navbar:Main:Click",
  },
  {
    name: NavName.QrCode,
    icon: CardIcon,
    iconActive: CardColorIcon,
    text: "Карта",
    link: Routes.QrCode,
    counter: "Navbar:Card:Click",
  },
  {
    name: NavName.Profile,
    icon: HumanIcon,
    iconActive: HumanColorIcon,
    text: "Профиль",
    link: Routes.Profile,
    counter: "Navbar:My:Click",
  },
];

const router = useRouter();
const route = useRoute();
const { send } = useAnalytics();

const active = computed<NavName | null>(() => {
  if (route.path === Routes.Profile) {
    return NavName.Profile;
  }

  if (route.path === Routes.QrCode) {
    return NavName.QrCode;
  }

  if (route.path === Routes.Main) {
    return NavName.Main;
  }

  return null;
});

onMounted(() => {
  send("Navbar:View");
});
</script>

<template>
  <div class="navbar-wrapper">
    <VWrapperLocal class="navbar">
      <div
        v-for="item in navItems"
        :key="item.name"
        class="navbar__item"
        :class="{
          navbar__item_active: active === item.name,
        }"
        @click="
          () => {
            send(item.counter);
            router.push(item.link);
          }
        "
      >
        <div class="navbar__item-icon">
          <component :is="active === item.name ? item.iconActive : item.icon" />
        </div>
        <div class="navbar__item-text">
          {{ item.text }}
        </div>
      </div>
    </VWrapperLocal>
  </div>
</template>

<style lang="postcss" scoped>
.navbar-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--color-black-10);
  background: var(--pl-surface-primary-default);
  z-index: var(--pl-z-index-over);
}

.navbar {
  display: flex;
  height: calc(var(--navbar-height) - 1px);
  align-items: flex-start;
  padding-top: 4px;

  &__item {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--pl-text-secondary);

    &_active {
      color: var(--pl-text-primary);
    }
  }

  &__item-text {
    font: var(--pl-font-caption-small-accent);
  }
}
</style>
