<script setup lang="ts">
import { computed, watch, ref, onMounted } from "vue";
import { vElementVisibility } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import CloseIcon from "~/assets/svg/icons/close.svg";
import AdvantageIcon from "~/assets/svg/icons/advantage.svg";
import useAnalytics from "~/composables/useAnalytics";
import { usePwaStore } from "~/store/pwa";
import { useModalsStore, MODALS } from "~/store/modals";
import { usePlatformStore } from "~/store/platform";

const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const modalsStore = useModalsStore();
const { send } = useAnalytics();

const isElementVisible = ref(false);
const visibilityEventEmitted = ref(false);
const isClosed = ref(false);
const showOpenButton = computed(
  () => pwaStore.state.appInstalled && !modalsStore.isOpen(MODALS.InstallSuccess),
);

const showBanner = computed(
  () =>
    !isClosed.value &&
    !platformStore.isPwa &&
    (platformStore.showAndroidAppBanner ||
      platformStore.showIosAppBanner ||
      pwaStore.state.installPromptBannerOpen ||
      pwaStore.state.showIosTutorial ||
      showOpenButton.value),
);

const onClose = () => {
  pwaStore.state.installPromptBannerOpen = false;
  pwaStore.state.showIosTutorial = false;
  isClosed.value = true;
  send("App:Prompt:Banner:Cancel");
};

const onOpenPwaClick = () => {
  if (platformStore.platformInfo.pwaLinkSupported) {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onButton = () => {
  send("App:Prompt:Banner:Click");
  if (pwaStore.state.showIosTutorial) {
    modalsStore.open(MODALS.PwaIosInstruction);
    return;
  }
  pwaStore.showPrompt();
};

const onElementVisibility = (visibility: boolean) => {
  isElementVisible.value = visibility;
};

const onEventsTriggering = () => {
  if (pwaStore.state.installPromptBannerOpen || pwaStore.state.showIosTutorial) {
    visibilityEventEmitted.value = true;
    send("App:Prompt:Banner:View");
  } else if (showOpenButton.value) {
    visibilityEventEmitted.value = true;
  }
};

watch(isElementVisible, (next) => {
  if (next && !visibilityEventEmitted.value) {
    onEventsTriggering();
  }
});

watch(showOpenButton, (next) => {
  if (visibilityEventEmitted.value && next) {
    send("App:Open:Banner:View");
  }
});

onMounted(() => {
  if (isElementVisible.value) {
    onEventsTriggering();
  }
});
</script>

<template>
  <div
    v-if="showBanner"
    v-element-visibility="onElementVisibility"
    class="install-app-banner"
    :class="{
      'install-app-banner__android-app-banner': platformStore.showAndroidAppBanner,
    }"
  >
    <div class="install-app-banner__wrapper">
      <div class="install-app-banner__close" @click="onClose">
        <CloseIcon />
      </div>

      <Component :is="AdvantageIcon" class="install-app-banner__icon" />

      <div class="install-app-banner__text">
        <VText font="body-small-accent-low" class="install-app-banner__text_title">
          Магнит Онлайн
        </VText>
        <VText font="caption-large-regular-low">
          Иконка для входа
        </VText>
      </div>
      <VButton
        v-if="pwaStore.state.appInstalled"
        class="install-app-banner__button"
        size="s"
        @click="onOpenPwaClick"
      >
        Открыть
      </VButton>
      <VButton
        v-else
        class="install-app-banner__button"
        :loading="pwaStore.state.installInProgress"
        size="s"
        @click="onButton"
      >
        Добавить
      </VButton>
    </div>
  </div>
</template>

<style lang="postcss">
:root {
  --install-banner-height: 52px;
}

.install-app-banner {
  display: flex;
  width: 100%;
  height: var(--install-banner-height);
  flex-shrink: 0;

  @media (--pl-viewport-from-ml) {
    display: none;
  }

  @media all and (display-mode: standalone) {
    display: none;
  }

  &__android-app-banner {
    @media all and (display-mode: standalone) {
      display: flex;
    }
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: var(--pl-unit-x2) var(--pl-unit-x4);
    align-items: center;
    width: 100%;
    max-width: 1200px;
    transition: top 0.2s;
    height: var(--install-banner-height);
    display: flex;
    z-index: 5;
    background: white;
    border-bottom: 1px solid var(--pl-divider-primary);
  }

  &__close {
    display: flex;
  }

  &__icon {
    margin-left: var(--pl-unit-x1);
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: var(--pl-unit-x2);

    &_title {
      font-family: var(--pl-font-family-secondary);
    }
  }

  &__button {
    margin-left: auto;
  }
}
</style>
