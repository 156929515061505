<script setup lang="ts">
import { ref, reactive, watch, onBeforeMount, onMounted } from "vue";
import { useNetwork } from "@vueuse/core";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VLayout from "~/components/VLayout.vue";
import VInstallAppBanner from "~/components/VInstallAppBanner.vue";
import VInstallCompleteModal from "~/containers/Modals/VInstallCompleteModal.vue";
import useToasts from "~/composables/useToasts";
import { useAuthStore } from "~/store/auth";
import { useOTPStore } from "~/store/otp";
import { usePwaStore } from "~/store/pwa";
import { useUserStore } from "~/store/user";
import { useModalsStore } from "~/store/modals";
import { useAbStore } from "~/store/ab";
import VPwaIosInstructionModal from "~/containers/Modals/VPwaIosInstructionModal.vue";
import useFirebaseInit from "~/composables/useFirebaseInit";
import serviceWorkerInit from "~/sw";
import { loadScript } from "~/utils/etc";
import getPlatformInfo from "~/utils/getPlatformInfo";
import { usePlatformStore } from "~/store/platform";
import { useSlotsStore } from "~/store/useSlotsStore";

const authStore = useAuthStore();
const otpStore = useOTPStore();
const userStore = useUserStore();
const pwaStore = usePwaStore();
const modalsStore = useModalsStore();
const abStore = useAbStore();
const toasts = useToasts();
const platformStore = usePlatformStore();
const { loadSlotApiInit } = useSlotsStore();

const config = useRuntimeConfig();

useFirebaseInit();

const network = reactive(useNetwork());

authStore.$onAction(({ name }) => {
  if (name === "logout") {
    otpStore.$reset();
    authStore.$reset();
    userStore.$reset();

    userStore.clearSavedUUID();
  }
});

onBeforeMount(async () => {
  await serviceWorkerInit();
});

onMounted(async () => {
  addEventListener("beforeinstallprompt", (e: Event) => {
    e.preventDefault();
    pwaStore.state.deferredInstallPrompt = e;
    pwaStore.state.installPromptBannerOpen = true;
  });
  const platformInfo = getPlatformInfo();
  if (platformInfo) {
    platformStore.setValues(platformInfo);
  }
  if (await pwaStore.checkAppInstall()) {
    pwaStore.state.appInstalled = true;
  }
});

watch(
  () => authStore.state.authorized,
  async (authorized) => {
    if (authorized) {
      await userStore.getProfile();
      await userStore.getPermissions();
      await userStore.getFavoriteCategories();
      await abStore.getExperiments();
    }
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      await serviceWorkerInit();
    }
  },
);
onMounted(async () => {
  if (authStore.state.authorized) {
    await userStore.getProfile();
    await userStore.getPermissions();
    await userStore.getFavoriteCategories();
    await abStore.getExperiments();
  }
});

watch(
  () => network.isOnline,
  (isOnline) => {
    if (!isOnline) {
      toasts.warning({ text: "Нет интернета. Проверьте подключение к сети." });
    }
  },
);

onMounted(() => {
  loadSlotApiInit();
  loadScript(`/scripts/${config.public.kfpCid}.das.obf.js`);
  document.body.classList.toggle("device_ios", platformStore.platformInfo.osName === "ios");
  if (platformStore.platformInfo.osName === "ios" && !platformStore.platformInfo.pwa) {
    pwaStore.state.showIosTutorial = true;
  }
});

const showCookie = ref(false);
const cookieTimerId = ref<NodeJS.Timeout | null>(null);

watch(
  () => modalsStore.openedModals.length,
  (next) => {
    if (next) {
      if (cookieTimerId.value) {
        clearTimeout(cookieTimerId.value);
      }
      showCookie.value = false;
    } else {
      cookieTimerId.value = setTimeout(() => {
        if (!modalsStore.openedModals.length) {
          showCookie.value = true;
        }
      }, 2000);
    }
  },
);

onMounted(() => {
  cookieTimerId.value = setTimeout(() => {
    if (!modalsStore.openedModals.length) {
      showCookie.value = true;
    }
  }, 2000);
});
</script>

<template>
  <VInstallAppBanner />

  <VLayout>
    <slot />
    <VCookiePolicyPopup :hide="!showCookie || !!$route.meta?.hideCookiePopup" />
    <VInstallCompleteModal />
    <VPwaIosInstructionModal />
    <VTopButton class="app-top-button" />
  </VLayout>
</template>

<style scoped lang="postcss">
.app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-top-button.pl-button {
  position: fixed;
}
</style>
